html, body {
  color: #bec7d2;
  height: 100%;
  text-shadow: 1px 1px #0000004d;
  background: #111;
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: .92rem;
  font-weight: normal;
  line-height: 1.22rem;
}

body {
  overflow-y: scroll;
}

.container {
  width: 100%;
  max-width: 595px;
  margin: 0 auto;
  padding: 8px 0 16px 16px;
  overflow: hidden;
}

#wrap {
  min-height: 100%;
}

main {
  padding-bottom: 28px;
}

::selection {
  color: #f7e279;
  background: #0009;
}

strong {
  color: #f7e279;
  font-weight: 700;
}

h1 {
  text-shadow: 1px 1px #00000080;
  color: #e09456;
  word-wrap: break-word;
  margin: 8px 0;
  padding: 8px 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.15rem;
  overflow: hidden;
}

h1 img {
  margin: 0 8px;
  position: absolute;
}

h1 a {
  text-decoration: underline;
}

h2 {
  text-shadow: 1px 1px #00000080;
  color: #e09456;
  word-wrap: break-word;
  margin: 8px 0 0;
  padding: 8px 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.06rem;
  font-weight: bold;
  line-height: 1.15rem;
  overflow: hidden;
}

h4 {
  color: #f7e279;
  margin: 8px 0;
  font-weight: 700;
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #fff;
}

p {
  margin: 0;
  padding: 0 0 11.2px;
  line-height: 1.35rem;
}

ul {
  text-indent: -1em;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 0;
  padding-left: 1em;
}

ul li {
  list-style-type: square;
  list-style-position: inside;
}

li {
  padding-bottom: 11.2px;
  line-height: 1.35rem;
}

table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 0;
}

td {
  min-width: 20%;
  border: 0;
  padding: 0;
}

.note {
  color: gray;
  font-size: 85%;
}

#bingoPage #results {
  float: left;
  width: 100%;
  text-align: right;
  margin-right: 16px;
}

#bingoPage .newcard, #bingoPage .option {
  text-align: center;
  color: #bec7d2;
  float: left;
  user-select: none;
  cursor: pointer;
  width: 105px;
  background-color: #424242;
  background-image: linear-gradient(#424242, #303030);
  border-top: 1px solid #4c4c4c;
  border-bottom: 1px solid #000;
  border-left: 1px solid #474747;
  border-right: 0 solid #000;
  border-radius: 5px;
  margin: 0 16px 0 0;
  padding: 4px 0;
  font-weight: 700;
  display: block;
  box-shadow: 1px 1px 2px #0009;
}

#bingoPage .newcard:hover, #bingoPage .option:hover {
  color: #fff;
  background-color: #3c70df;
  background-image: linear-gradient(#3c70df, #235cd4);
  border-top: 1px solid #4e7de2;
  border-bottom: 1px solid #102a63;
  border-left: 1px solid #4576e0;
  border-right: 0 solid #102a63;
}

#bingoPage .newcard:active, #bingoPage .option:active {
  color: #fff;
  background-color: #1448b3;
  background-image: linear-gradient(#1448b3, #103b93);
  border-top: 1px solid #164fc5;
  border-bottom: 1px solid #071a40;
  border-left: 1px solid #154cbc;
  border-right: 0 solid #071a40;
  box-shadow: 0 0 #0009;
}

#bingoPage .newcard:last-child, #bingoPage .option:last-child {
  margin-right: 0;
}

#bingoPage #bingo {
  user-select: none;
  margin-top: 16px;
}

#bingoPage #bingo tr:first-child td {
  height: 30px;
  color: #3c70df;
  background: #000811;
  border: 1px solid #0a245a;
  font-size: 75%;
  font-weight: 700;
}

#bingoPage #bingo .short {
  height: 30px;
}

#bingoPage #bingo tr:first-child td:hover, #bingoPage #bingo tr:last-child td:hover {
  color: #fff;
  background: #1448b3;
}

#bingoPage #bingo tr td {
  cursor: pointer;
  width: 95px;
  height: 90px;
  text-align: center;
  background: #000;
  border: 1px solid #424242;
  padding: 0 5px;
  box-shadow: inset 0 0 50px #0009;
}

#bingoPage.modern #bingo tr td {
  box-shadow: none;
}

#bingoPage #bingo tr td:first-child {
  width: 35px;
  color: #3c70df;
  background: #000811;
  border: 1px solid #0a245a;
  font-size: 75%;
  font-weight: 700;
}

#bingoPage #bingo tr td:first-child:hover {
  color: #fff;
  background: #1448b3;
}

#bingoPage #bingo tr td:hover, #bingoPage #bingo tr td.hover {
  color: #fff;
  background: #001a36;
}

#bingoPage #bingo tr td.greensquare {
  background: #051;
}

#bingoPage #bingo tr td.greensquare:hover, #bingoPage #bingo tr td.greensquare.hover {
  background: #072;
}

#bingoPage #bingo tr td.redsquare {
  background: #501;
}

#bingoPage #bingo tr td.redsquare:hover, #bingoPage #bingo tr td.redsquare.hover {
  background: #702;
}

#bingoPage #about_bingo {
  float: right;
  width: 350px;
  margin-right: 16px;
}

#bingoPage #bingo #bingo-info, #bingoPage #bingo #bingo-info:hover {
  color: #bec7d2;
  box-shadow: none;
  cursor: default;
  user-select: text;
  text-align: right;
  background-color: #0000;
  border: none;
  padding: 0;
}

* {
  box-sizing: border-box;
}

html, body {
  color: #bec7d2;
  height: 100%;
  text-shadow: 1px 1px #0000004d;
  background: #111;
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: .92rem;
  font-weight: normal;
  line-height: 1.22rem;
  overflow-y: hidden;
}

strong {
  color: #f7e279;
  font-weight: 700;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #fff;
}

p {
  margin: 0;
}

table {
  height: 100%;
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 0;
}

td {
  border: 0;
  padding: 0;
}

#board-popout-page {
  height: 100%;
  width: 100%;
  padding: 10px;
}

#bingoPage {
  height: 100%;
  width: 100%;
  text-align: right;
  flex-flow: column;
  flex-shrink: 0;
  display: flex;
}

#board {
  height: 100%;
}

#bingo {
  user-select: none;
}

#bingo-info {
  margin: 0;
}

@media (max-height: 480px), (max-width: 420px) {
  #bingo-info {
    display: none;
  }
}

#bingo .rowcol {
  color: #3c70df;
  background: #000811;
  border: 1px solid #0a245a;
  font-size: 75%;
  font-weight: 700;
}

#bingo .rowcol:hover {
  color: #fff;
  background: #1448b3;
}

#bingo tr {
  width: 100%;
}

#bingo td {
  height: 17.6%;
  width: 18.6%;
}

#bingo tr:first-child td, #bingo tr:last-child td, #bingo .short {
  height: 6%;
  color: #3c70df;
  background: #000811;
  border: 1px solid #0a245a;
  font-size: 75%;
  font-weight: 700;
}

#bingo tr:first-child td:hover, #bingo tr:last-child td:hover {
  color: #fff;
  background: #1448b3;
}

#bingo tr td {
  cursor: pointer;
  text-align: center;
  background: #000;
  border: 1px solid #424242;
  padding: 0 5px;
  box-shadow: inset 0 0 50px #0009;
}

#bingoPage.modern #bingo tr td {
  box-shadow: none;
}

#bingo tr td:first-child {
  width: 7%;
  color: #3c70df;
  background: #000811;
  border: 1px solid #0a245a;
  font-size: 75%;
  font-weight: 700;
}

#bingo tr td:first-child:hover {
  color: #fff;
  background: #1448b3;
}

#bingo tr td:hover, #bingo tr td.hover {
  color: #fff;
  background: #001a36;
}

#bingo tr td.greensquare {
  background: #051;
}

#bingo tr td.greensquare:hover, #bingo tr td.greensquare.hover {
  background: #072;
}

#bingo tr td.redsquare {
  background: #501;
}

#bingo tr td.redsquare:hover, #bingo tr td.redsquare.hover {
  background: #702;
}

#links {
  float: right;
  text-align: right;
  padding-right: 2em;
}

#links a:hover, #links a.hover {
  text-decoration: underline;
}

/*# sourceMappingURL=index.87ebc830.css.map */
