html,
body {
  background: #111111;
  color: #bec7d2;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 0.92rem;
  line-height: 1.22rem;
  padding: 0;
  margin: 0;
  height: 100%;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
}

body {
  overflow-y: scroll;
}

.container {
  width: 100%;
  max-width: 595px;
  margin: 0 auto;
  padding: 8px 0 16px 16px;
  overflow: hidden;
}

#wrap {
  min-height: 100%;
}

main {
  padding-bottom: 28px;
}

::selection {
  background: rgba(0, 0, 0, 0.6);
  color: #f7e279;
}

strong {
  color: #f7e279;
  font-weight: 700;
}

h1 {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.50rem;
  line-height: 1.15rem;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  color: #e09456;
  margin: 8px 0 8px 0;
  padding: 8px 0;
  word-wrap: break-word;
  overflow: hidden;
}

h1 img {
  position: absolute;
  margin: 0 8px;
}

h1 a {
  text-decoration: underline;
}

h2 {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.06rem;
  line-height: 1.15rem;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  color: #e09456;
  margin: 8px 0 0 0;
  padding: 8px 0;
  word-wrap: break-word;
  overflow: hidden;
}

h4 {
  display: block;
  margin: 8px 0;
  color: #f7e279;
  font-weight: 700;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #ffffff;
}

p {
  margin: 0px;
  padding: 0 0 11.2px;
  line-height: 1.35rem;
}

ul {
  margin-left: 0;
  padding-left: 1em;
  text-indent: -1em;
  margin-top: 2px;
  margin-bottom: 2px;
}
ul li {
  list-style-type: square;
  list-style-position: inside;
}

li {
  line-height: 1.35rem;
  padding-bottom: 11.2px;
}

table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 0;
}

td {
  border: 0;
  padding: 0;
  min-width: 20%;
}

.note {
  color: #808080;
  font-size: 85%;
}

#bingoPage #results {
  float: left;
  width: 100%;
  margin-right: 16px;
  text-align: right;
}

#bingoPage .newcard,
#bingoPage .option {
  background-color: #424242;
  background-image: linear-gradient(to bottom, #424242, #303030);
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #4c4c4c #000000 #000000 #474747;
  text-align: center;
  display: block;
  font-weight: 700;
  color: #bec7d2;
  margin: 0;
  float: left;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
  width: 105px;
  margin-right: 16px;
  padding: 4px 0;
}

#bingoPage .newcard:hover,
#bingoPage .option:hover  {
  background-color: #3c70df;
  background-image: linear-gradient(to bottom, #3c70df, #235cd4);
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #4e7de2 #102a63 #102a63 #4576e0;
  color: #ffffff;
}

#bingoPage .newcard:active,
#bingoPage .option:active {
  background-color: #1448b3;
  background-image: linear-gradient(to bottom, #1448b3, #103b93);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.6);
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #164fc5 #071a40 #071a40 #154cbc;
  color: #ffffff;
}

#bingoPage .newcard:last-child,
#bingoPage .option:last-child  {
  margin-right: 0;
}

#bingoPage #bingo {
  margin-top: 16px;
  user-select: none;
}

#bingoPage #bingo tr:first-child td {
  height: 30px;
  background: #000811;
  font-size: 75%;
  color: #3c70df;
  font-weight: 700;
  border: 1px #0a245a solid;
}

#bingoPage #bingo .short {
  height: 30px;
}

#bingoPage #bingo tr:first-child td:hover,
#bingoPage #bingo tr:last-child td:hover {
  color: #ffffff;
  background: #1448b3;
}

#bingoPage #bingo tr td {
  box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.6);
  padding: 0 5px;
  cursor: pointer;
  width: 95px;
  height: 90px;
  text-align: center;
  border: 1px #424242 solid;
  background: #000;
}

#bingoPage.modern #bingo tr td {
  box-shadow: none;
}

#bingoPage #bingo tr td:first-child {
  width: 35px;
  background: #000811;
  font-size: 75%;
  color: #3c70df;
  font-weight: 700;
  border: 1px #0a245a solid;
}

#bingoPage #bingo tr td:first-child:hover {
  color: #ffffff;
  background: #1448b3;
}

#bingoPage #bingo tr td:hover,
#bingoPage #bingo tr td.hover {
  background: #001a36;
  color: #fff;
}

#bingoPage #bingo tr td.greensquare {
  background: #005511;
}

#bingoPage #bingo tr td.greensquare:hover,
#bingoPage #bingo tr td.greensquare.hover {
  background: #007722;
}

#bingoPage #bingo tr td.redsquare {
  background: #550011;
}

#bingoPage #bingo tr td.redsquare:hover,
#bingoPage #bingo tr td.redsquare.hover {
  background: #770022;
}

#bingoPage #about_bingo {
  float: right;
  width: 350px;
  margin-right: 16px;
}


#bingoPage #bingo #bingo-info,
#bingoPage #bingo #bingo-info:hover {
  background-color: transparent;
  color: #bec7d2;
  box-shadow: none;
  padding: 0;
  border: none;
  cursor: default;
  user-select: text;
  text-align: right;
  border: none;
}

* {
  box-sizing: border-box;
}

html,
body {
  background: #111111;
  color: #bec7d2;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 0.92rem;
  line-height: 1.22rem;
  padding: 0;
  margin: 0;
  height: 100%;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
  overflow-y: hidden;
}

strong {
  color: #f7e279;
  font-weight: 700;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #ffffff;
}

p {
  margin: 0px;
}

table {
  height: 100%;
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 0;
}

td {
  border: 0;
  padding: 0;
}

#board-popout-page {
  height: 100%;
  width: 100%;
  padding: 10px;
}

#bingoPage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  text-align: right;
}

#board {
  height: 100%;
}

#bingo {
  user-select: none;
}

#bingo-info {
  margin: 0px 0px;
}

@media all and (max-height: 480px), all and (max-width: 420px) {
  #bingo-info { display: none; }
}

#bingo .rowcol {
  background: #000811;
  font-size: 75%;
  color: #3c70df;
  font-weight: 700;
  border: 1px #0a245a solid;
}

#bingo .rowcol:hover {
  color: #ffffff;
  background: #1448b3;
}

#bingo tr {
  width: 100%;
}

#bingo td {
  height: 17.6%;
  width: 18.6%;
}

#bingo tr:first-child td,
#bingo tr:last-child td,
#bingo .short {
  height: 6%;
  background: #000811;
  font-size: 75%;
  color: #3c70df;
  font-weight: 700;
  border: 1px #0a245a solid;
}

#bingo tr:first-child td:hover,
#bingo tr:last-child td:hover {
  color: #ffffff;
  background: #1448b3;
}

#bingo tr td {
  box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.6);
  padding: 0 5px;
  cursor: pointer;
  text-align: center;
  border: 1px #424242 solid;
  background: #000;
}

#bingoPage.modern #bingo tr td {
  box-shadow: none;
}

#bingo tr td:first-child {
  width: 7%;
  background: #000811;
  font-size: 75%;
  color: #3c70df;
  font-weight: 700;
  border: 1px #0a245a solid;
}

#bingo tr td:first-child:hover {
  color: #ffffff;
  background: #1448b3;
}

#bingo tr td:hover,
#bingo tr td.hover {
  background: #001a36;
  color: #fff;
}

#bingo tr td.greensquare {
  background: #005511;
}

#bingo tr td.greensquare:hover,
#bingo tr td.greensquare.hover {
  background: #007722;
}

#bingo tr td.redsquare {
  background: #550011;
}

#bingo tr td.redsquare:hover,
#bingo tr td.redsquare.hover {
  background: #770022;
}

#links {
  float: right;
  padding-right: 2em;
  text-align: right;
}

#links a:hover,
#links a.hover {
  text-decoration: underline;
}